var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.theme ? _c('div', {
    staticClass: "index-face-scan"
  }, [_c('div', {
    staticClass: "f-head-img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-face-scan.png`)
    }
  })]), _c('div', {
    staticClass: "f-info"
  }, [_c('div', {
    staticClass: "f-info-title"
  }, [_vm._v(" Human Verification ")]), _c('div', {
    staticClass: "reward"
  }, [_vm._v(" +" + _vm._s(_vm.userData.facescan_reward) + " HID ")])]), _c('div', {
    staticClass: "f-btns"
  }, [!_vm.isVerify ? _c('div', {
    staticClass: "btn",
    class: _vm.isLoading && 'gray',
    on: {
      "click": _vm.doVerify
    }
  }, [_vm._v(" Verify "), _vm.isLoading ? _c('div', {
    staticClass: "r-loading"
  }, [_c('round-loading', {
    attrs: {
      "color": '#eee'
    }
  })], 1) : _vm._e()]) : _c('div', {
    staticClass: "btn",
    class: _vm.isLoading && 'gray',
    on: {
      "click": _vm.doCheckFaceScan
    }
  }, [_vm._v(" Check "), _vm.isLoading ? _c('div', {
    staticClass: "r-loading"
  }, [_c('round-loading', {
    attrs: {
      "color": '#eee'
    }
  })], 1) : _vm._e()])])]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "upgold__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/facescan.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "upgold__title"
  }, [_vm._v(_vm._s(_vm.$lang("I am not a robot")))]), _c('div', {
    staticClass: "upgold__reward"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/icon-coin.png`),
      "alt": ""
    }
  }), _vm._v(" +50 " + _vm._s(_vm.setting.coin) + " ")]), _c('div', {
    staticClass: "btns"
  }, [[_c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.openScan
    }
  }, [_vm._v(_vm._s(_vm.$lang("Verify")))])], [_c('div', {
    staticClass: "btn",
    class: _vm.isLoading && 'loading-btn',
    on: {
      "click": _vm.checkScan
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Check")) + " "), _vm.isLoading ? _c('div', {
    staticClass: "round-loading"
  }, [_c('round-loading')], 1) : _vm._e()])]], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/sign_top.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(_vm._s(_vm.$lang("Claim Reward")))]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$lang("You can only choose one of the Reward")) + " ")]), _c('div', {
    staticClass: "energy__img__btns"
  }, [_c('div', {
    staticClass: "energy__img__btn",
    class: {
      select_btn: _vm.selectIndex == 0
    },
    on: {
      "click": function ($event) {
        return _vm.setSelectIndex(0);
      }
    }
  }, [_c('div', {
    staticClass: "energy__img_gox"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/icon-coin-sign.png`)
    }
  })]), _c('div', {
    staticClass: "img__btn__title"
  }, [_vm._v(_vm._s(_vm.setting.coin))]), _c('div', {
    staticClass: "img__btn__coin"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/icon-coin-sign.png`)
    }
  }), _vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.taskInfo.coins, 2)) + " ")])]), _c('div', {
    staticClass: "energy__img__btn",
    class: {
      select_btn: _vm.selectIndex == (_vm.theme == 'human' ? 1 : 2)
    },
    on: {
      "click": function ($event) {
        return _vm.setSelectIndex(_vm.theme == 'human' ? 1 : 2);
      }
    }
  }, [_c('div', {
    staticClass: "energy__img_gox"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/sign-premium.png`)
    }
  })]), _c('div', {
    staticClass: "img__btn__title"
  }, [_vm._v(_vm._s(_vm.setting.coin) + " Premium")]), _c('div', {
    staticClass: "img__btn__coin"
  }, [_c('div', {
    staticClass: "img__btn__more__coin"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/icon-coin-sign.png`)
    }
  }), _vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.taskInfo.coins * (_vm.theme == 'human' ? 2 : 3), 2)) + " ")]), _vm.taskInfo.stocks > 0 ? _c('div', {
    staticClass: "img__btn__more__key"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/earn-key.png`)
    }
  }), _vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.taskInfo.stocks, 2)) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "btn-level",
    style: _vm.isFlag ? {
      background: _vm.themeMainColor(_vm.theme).main
    } : 'background: gray',
    on: {
      "click": _vm.onClaim
    }
  }, [_vm.isFlag ? _c('span', [_vm._v(_vm._s(_vm.$lang("Claim")))]) : _vm._e(), !_vm.isFlag ? _c('div', {
    staticClass: "loadingx"
  }, [_c('round-loading')], 1) : _vm._e()])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
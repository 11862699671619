import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const rankingVuex = createNamespacedHelpers("ranking");
export default {
  name: "leaderboard",
  data() {
    return {
      popFaceTipShow: false
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...rankingVuex.mapState(["firendList", "firendTotal"])
  },
  mounted() {
    // if (!this.firendList || this.firendList.length == 0) {
    //   this.getFirendList();
    // }
    setTimeout(() => {
      if (this.theme === 'human' && this.userData.joined_facescan == 0 && this.userData.parent_info) {
        this.popFaceTipShow = true;
      }
    }, 200);
  },
  methods: {
    ...rankingVuex.mapActions(["getFirendList"]),
    ...userVuex.mapActions(["join"]),
    async onJoin() {
      this.WebApp.openTelegramLink(this.setting.socials.channel);
      await this.join(["channel", null, 1]);
    },
    onSkip(page) {
      this.$router.push(page);
    }
  }
};
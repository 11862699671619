import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const facescanVuex = createNamespacedHelpers("facescan");
export default {
  name: "facescan",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...facescanVuex.mapState(["rId"])
  },
  data() {
    return {
      url: 'https://verify.biomatrix.ai/face-scan?eventCode=TAPCOINANTIBOT&channelCode=HVGZ2R&requestId=',
      uid: '',
      appid: '',
      iframeUrl: '',
      loading: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.uid = '';
    this.iframeUrl = '';
    this.requestId = '';
    this.loading = false;
    window.removeEventListener('message', this.callback);
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    this.WebApp.BackButton.hide();
    window.addEventListener('message', this.callback);
    this.uid = this.userData.tg_id;
    this.appid = this.$route.query.id ?? null;
    this.loading = true;
    await this.getRequestId(this.appid);
    if (this.rId) {
      this.iframeUrl = this.url + this.rId + '&uid=' + this.uid;
    }
    setTimeout(() => {
      this.WebApp.BackButton.hide();
    }, 0);
  },
  methods: {
    ...facescanVuex.mapActions(['getRequestId']),
    callback(event) {
      if (event.data === 'callParentMethod') {
        this.WebApp.close();
      }
    },
    onClose() {
      this.WebApp.close();
    },
    start() {
      this.loading = false;
    }
  }
};
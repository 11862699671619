import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const dailyViex = createNamespacedHelpers("daily");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "popSign",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"])
  },
  watch: {
    taskInfo: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isFlag: true,
      selectIndex: this.theme == 'human' ? 1 : 2
    };
  },
  methods: {
    ...dailyViex.mapActions(["getSteps", "updatedDaily"]),
    ...userVuex.mapMutations(['setUserData']),
    async onClaim() {
      if (this.selectIndex == 0) {
        this.onSubmit();
      } else {
        //this.$loading.show()
        try {
          // const AdController = await window.Adsgram.init({ blockId: this.taskInfo.advid.toString() });
          // await AdController.show().then((result) => {
          //     this.$loading.hide()
          //     if(result.done  && !this.taskInfo.completed)
          //       this.onSubmit()
          // }).catch((result) => {
          //     console.log(result)
          //     this.$loading.hide()
          // })
          if (!this.taskInfo.completed) {
            if (this.theme == 'car') this.WebApp.openLink('https://finacecloud.com?channel=478');else this.WebApp.openLink('https://finacecloud.com?channel=479');
            this.isFlag = false;
            setTimeout(() => {
              this.isFlag = true;
              this.onSubmit();
              //console.log('submit')
              //this.$loading.hide()
              //this.$nextTick(()=>{
              coinAnimFn({
                containerRef: document.getElementById("destoryCoinContainerRef"),
                ref: document.getElementById("coinRef"),
                img: require(`@images/${this.theme}/index-icon-small-coin.png`),
                radius: 300,
                numCoins: 50,
                callback: () => {}
              }, this);
              //})
            }, 20000);
          }
        } catch (e) {
          this.isFlag = true;
          clearInterval(this.loadT);
          console.log(e);
          //this.$loading.hide()
        }

        return;
      }
    },
    async onSubmit() {
      if (!this.isFlag) return;
      this.isFlag = false;
      this.updatedDaily([1, this.selectIndex, rs => {
        if (rs.user) {
          let newUserData = {
            ...this.userData,
            ...rs.user
          };
          this.setUserData(newUserData);
        }
        this.$parent.popSignShow = false;
        this.isFlag = true;
      }, () => {
        this.isFlag = true;
      }]);
    },
    setSelectIndex(index) {
      this.selectIndex = index;
    }
  }
};
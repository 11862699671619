var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "frends",
    class: _vm.theme,
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    }
  }, [_c('div', {
    staticClass: "frends-bg",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/bg4.jpg`)})`
    }
  }), _c('div', {
    staticClass: "frends-main"
  }, [_vm.theme === 'car' ? _c('div', {
    staticClass: "car"
  }, [_c('div', {
    staticClass: "sign-btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/sign');
      }
    }
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/sign.png`)
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$lang("Daily Login")))])]), _c('div', {
    staticClass: "car-main",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/home-logo.png`)})`
    }
  }), _c('div', {
    staticClass: "num",
    style: {
      marginTop: '-80px',
      color: _vm.themeMainColor(_vm.theme).homeText,
      fontWeight: 'bold'
    }
  }, [_c('span', {
    staticClass: "num__label"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)) + " " + _vm._s(_vm.setting.coin))])])]) : _vm._e(), _vm.theme === 'human' ? _c('div', {
    staticClass: "human"
  }, [_c('div', {
    staticClass: "human-main",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/home-logo.png`)})`
    }
  }, [_c('div', {
    staticClass: "human-pass"
  }, [_c('div', {
    staticClass: "human-pass__title"
  }, [_vm._v(_vm._s(_vm.$lang("MY HUMAN PASS")))]), _c('div', {
    staticClass: "human-pass__label"
  }, [_vm._v(_vm._s(_vm.userData.uid))])])]), _c('div', {
    staticClass: "num",
    style: {
      color: _vm.themeMainColor(_vm.theme).homeText,
      fontWeight: 'bold'
    }
  }, [_c('div', {
    staticClass: "num__title"
  }, [_vm._v(_vm._s(_vm.$lang("My Human Index(HID)")))]), _c('span', {
    staticClass: "num__label"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)))])]), _vm.userData.joined_facescan == 0 ? _c('index-face-scan') : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "com",
    class: `com-${_vm.theme}`,
    style: {
      background: _vm.themeMainColor(_vm.theme).listBg
    }
  }, [_c('div', {
    staticClass: "com__title"
  }, [_vm.theme === 'car' ? [_vm._v(" " + _vm._s(_vm.setting.coin) + " " + _vm._s(_vm.$lang("CHANNEL")) + " ")] : _vm._e(), _vm.theme === 'human' ? [_vm._v(" " + _vm._s(_vm.$lang("Join Human Pass Channel")) + " ")] : _vm._e()], 2), _c('div', {
    staticClass: "com__subtitle"
  }, [_vm._v(_vm._s(_vm.$lang("Home for Telegram OGs")))]), _c('div', {
    staticClass: "btn-join",
    style: {
      background: _vm.themeMainColor(_vm.theme).btnHomeMain,
      color: _vm.themeMainColor(_vm.theme).btnHomeText
    },
    on: {
      "click": function ($event) {
        return _vm.onJoin();
      }
    }
  }, [_c('span', {
    style: {
      marginTop: _vm.theme == 'human' ? _vm.remTran(6) : _vm.remTran(0)
    }
  }, [_vm._v(_vm._s(_vm.$lang("Join")) + " +" + _vm._s(_vm.setting.reward.joined.channel) + " " + _vm._s(_vm.setting.coin))])])]), _c('div', {
    staticClass: "reward",
    class: `reward-${_vm.theme}`
  }, [_vm.theme == 'car' ? _c('div', {
    staticClass: "reward-title"
  }, [_vm._v(_vm._s(_vm.$lang("Your reward")))]) : _vm.theme == 'human' ? _c('div', {
    staticClass: "reward-title"
  }, [_vm._v(_vm._s(_vm.$lang("Your Humanity")))]) : _vm._e(), _c('div', {
    staticClass: "reward-main"
  }, [_vm.theme == 'human' && _vm.userData.joined_facescan > 0 ? _c('div', {
    staticClass: "reward-item",
    style: {
      background: _vm.themeMainColor(_vm.theme).rewardItemBg
    }
  }, [_c('div', {
    staticClass: "reward-item__icon",
    style: {
      background: _vm.themeMainColor(_vm.theme).iconBg
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-face-scan.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "reward-item__name"
  }, [_vm._v(_vm._s(_vm.$lang("human Verification")))]), _c('div', {
    staticClass: "reward-item__num",
    style: {
      color: _vm.themeMainColor(_vm.theme).homeText
    }
  }, [_vm._v("+" + _vm._s(_vm._f("toThousands")(_vm.userData.facescan_reward)) + " " + _vm._s(_vm.setting.coin))])]) : _vm._e(), _c('div', {
    staticClass: "reward-item",
    style: {
      background: _vm.themeMainColor(_vm.theme).rewardItemBg
    }
  }, [_c('div', {
    staticClass: "reward-item__icon",
    style: {
      background: _vm.themeMainColor(_vm.theme).iconBg
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/ag.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "reward-item__name"
  }, [_vm._v(_vm._s(_vm.$lang("Account age")))]), _c('div', {
    staticClass: "reward-item__num",
    style: {
      color: _vm.themeMainColor(_vm.theme).homeText
    }
  }, [_vm._v("+" + _vm._s(_vm._f("toThousands")(_vm.userData.age_reward)) + " " + _vm._s(_vm.setting.coin))])]), _c('div', {
    staticClass: "reward-item",
    style: {
      background: _vm.themeMainColor(_vm.theme).rewardItemBg
    }
  }, [_c('div', {
    staticClass: "reward-item__icon",
    style: {
      background: _vm.themeMainColor(_vm.theme).iconBg
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tp.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "reward-item__name"
  }, [_vm._v(_vm._s(_vm.$lang("Telegram Premium")))]), _c('div', {
    staticClass: "reward-item__num",
    style: {
      color: _vm.themeMainColor(_vm.theme).homeText
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.premium_reward)) + " " + _vm._s(_vm.userData.premium_reward > 0 ? _vm.setting.coin : " "))])]), _c('div', {
    staticClass: "reward-item",
    style: {
      background: _vm.themeMainColor(_vm.theme).rewardItemBg
    }
  }, [_c('div', {
    staticClass: "reward-item__icon",
    style: {
      background: _vm.themeMainColor(_vm.theme).iconBg
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/it.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "reward-item__name"
  }, [_vm._v(_vm._s(_vm.$lang("Invited friends")))]), _c('div', {
    staticClass: "reward-item__num",
    style: {
      color: _vm.themeMainColor(_vm.theme).homeText
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.invite_reward)) + " " + _vm._s(_vm.userData.invite_reward > 0 ? _vm.setting.coin : " "))])])])])]), _c('pop-face-tip', {
    attrs: {
      "show": _vm.popFaceTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popFaceTipShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const facescanVuex = createNamespacedHelpers("facescan");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
import cookie from "js-cookie";
import svgIcon from './svgIcon.vue';
export default {
  components: {
    svgIcon
  },
  name: "popFaceTip",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      isVerify: false,
      isLoading: false,
      checkCount: 1
    };
  },
  props: {},
  watch: {
    "$parent.popFaceTipShow": {
      handler(newVal, oldVal) {
        if (!newVal && typeof oldVal !== 'undefined') {
          if (FS) {
            FS.init({
              keyid: 'hpe46b04131b28d681',
              tgId: this.userData.tg_id
            });
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...facescanVuex.mapActions(['doCheck']),
    doVerify() {
      if (FS) {
        FS.scan();
      }
      //this.isVerify = true
      this.isLoading = true;
      setTimeout(() => {
        this.isVerify = true;
        this.isLoading = false;
      }, 5000);
    },
    doCheckFaceScan() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      setTimeout(() => {
        this.doCheck(['facescan', res => {
          this.isLoading = false;
          this.$parent.popFaceTipShow = false;
        }, rs => {
          if (rs.data && rs.data.status == -1) {
            if (this.checkCount > 0) {
              this.checkCount--;
            } else {
              this.checkCount = 1;
              this.isVerify = false;
            }
          } else if (rs.data && rs.data.status == 0) {
            this.checkCount = 1;
            this.isVerify = false;
          }
          this.isLoading = false;
        }]);
      }, 5000);
    }
  }
};
import { createNamespacedHelpers } from "vuex";
import facescan from "../store/modules/facescan";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const facescanVuex = createNamespacedHelpers("facescan");
export default {
  name: "popFaceScan",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      isLoading: false
    };
  },
  watch: {
    "$parent.popFaceScanShow": {
      handler(bool) {
        if (bool) {}
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...facescanVuex.mapActions(['doCheck']),
    openScan() {
      if (FS) {
        FS.scan();
      }
    },
    checkScan() {
      let that = this;
      if (that.isLoading) {
        return;
      }
      that.isLoading = true;
      this.doCheck(['facescan', res => {
        that.isLoading = false;
        that.$toasted.success(res.message);
        that.$parent.popFaceScanShow = false;
      }, () => {
        that.isLoading = false;
      }]);
    }
  },
  mounted() {
    if (FS) {
      FS.init({
        keyid: 'hpe46b04131b28d681',
        //授权key
        tgId: this.userData.tg_id //用户tgid
      });
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "90%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "tip-title"
  }, [_vm._v(_vm._s(_vm.$lang('Anti-bot verification')))]), _c('div', {
    staticClass: "tip-top"
  }, [_c('div', {
    staticClass: "s"
  }, [_vm.userData.parent_info.icon ? _c('img', {
    attrs: {
      "src": _vm.userData.parent_info.icon
    }
  }) : _vm._e()]), _vm.userData.parent_info.icon ? _c('div', {
    staticClass: "s"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-close"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "s"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/logo.jpg`)
    }
  })])]), _c('div', {
    staticClass: "tip-content"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Before claiming rewards and airdrops, please be sure to complete the anti-bot verification first')) + " ")]), _c('div', {
    staticClass: "tip-sub-title"
  }, [_vm._v(_vm._s(_vm.$lang('I am not a robot')))]), !_vm.isVerify ? _c('div', {
    staticClass: "btn-confirm",
    class: _vm.isLoading && 'gray',
    on: {
      "click": _vm.doVerify
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Verify and claim rewards')) + " "), _vm.isLoading ? _c('div', {
    staticClass: "r-loading"
  }, [_c('round-loading', {
    attrs: {
      "color": '#eee'
    }
  })], 1) : _vm._e()]) : _c('div', {
    staticClass: "btn-confirm",
    class: _vm.isLoading && 'gray',
    on: {
      "click": _vm.doCheckFaceScan
    }
  }, [_vm._v(" Check "), _vm.isLoading ? _c('div', {
    staticClass: "r-loading"
  }, [_c('round-loading', {
    attrs: {
      "color": '#eee'
    }
  })], 1) : _vm._e()])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    rId:null
  },
  mutations: {
    setRId: (state, payload)=> {
      state.rId = payload
    },
  },
  actions: {
    doCheck:async ({ state, commit },[target,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/facescan/check",{target});
      vailcode(
        rs,
        (rs) => {
          commit("user/setUserData", rs.data,{root:true});
          typeof callback=='function' && callback(rs)
        },
        (rs)=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
    getRequestId:async ({ state, commit }, appid) => {
      const rs = await Vue.prototype.$http.post("/facescan/id",{appid});
      vailcode(
        rs,
        (rs) => {
          commit("setRId", rs.data.requestId);
        }
      );
    },
  },
  getters: {},
};
